import React from 'react'

import { AgeGateContext } from 'components/ageGate/contexts'
import { TranslatedMessage } from 'components/common/TranslatedMessage'

type Props = {
  id: string
  inlineData?: Record<string, unknown>
}

export const TranslatedTextMessage = ({ id, inlineData = {} }: Props) => {
  const { messageKeys } = React.useContext(AgeGateContext)
  return <TranslatedMessage id={messageKeys[id]} values={inlineData} />
}
