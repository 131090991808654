import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { Icon } from '@viewlio/juulio-bridge/src/common/components/Icon';

interface Props {
  callToAction?: {
    displayText?: string;
    targetBlank?: boolean;
    url?: string;
  };
  className?: string;
  dangerousHTMLText?: string;
  icon?: string;
  text: ReactNode;
  title?: ReactNode;
}

const InlineNotification: FC<Props> = ({
  callToAction,
  className,
  dangerousHTMLText,
  icon,
  title,
  text,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classNames('inline-notification', className, {
        'inline-notification--with-icon': !!icon,
        'inline-notification--with-title': !!title,
      })}
    >
      {icon && (
        <div className="inline-notification__icon">
          <Icon name={icon} />
        </div>
      )}

      <div>
        {title && (
          <span className="inline-notification__title heading--small">
            {title}
          </span>
        )}
        {dangerousHTMLText ? (
          <span
            className="inline-notification__body text--body--small"
            dangerouslySetInnerHTML={{ __html: dangerousHTMLText }}
          />
        ) : (
          <span className="inline-notification__body text--body--small">
            {text}
          </span>
        )}

        {callToAction?.url && (
          <a
            className="inline-notification__call-to-action text--button"
            href={callToAction.url}
            target={callToAction.targetBlank ? '_blank' : '_self'}
          >
            {callToAction.displayText}
          </a>
        )}
      </div>
    </div>
  );
};

// We have tests that rely on named exports.
// https://github.com/enzymejs/enzyme/issues/1546
export { InlineNotification };
