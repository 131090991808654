import React, { FC } from 'react'

import { Dropdown } from '@juullabs/react-components'
import { SelectedState, StateListItem, StatesList } from '@viewlio/types/src/AgeGate'

import { TranslatedTextMessage } from 'components/ageGate/components/TranslatedTextMessage'
import { AgeGateContext } from 'components/ageGate/contexts/AgeGateContext'
import { TranslatedMessage } from 'components/common/TranslatedMessage'
import { useTranslations } from 'utils/translations/useTranslations'

import styles from './StateSelector.module.scss'

type Props = {
  callback(state: StateListItem): void
  isCanada?: boolean
  isLoading?: boolean
  selectedState?: SelectedState
  selector: StatesList
}

export const StateSelector: FC<Props> = ({
  isCanada = false,
  isLoading = false,
  selector,
  selectedState,
  callback,
}) => {
  const { translateMessage } = useTranslations()
  const { messageKeys } = React.useContext(AgeGateContext)

  const rawSelect = states => {
    const options = states.map(({ value, label }) => ({ id: value, label }))

    if (isCanada) {
      options.forEach(state => {
        state.label = translateMessage(
          `${messageKeys.STATES}.ca_${state.id.toLowerCase()}`,
        )
      })
    }

    options.unshift({
      id: '',
      label: translateMessage(messageKeys.STATES_DEFAULT),
    })

    return (
      <div className={styles.wrapper} data-testid='age-gate-states'>
        <Dropdown
          values={options}
          value={options.find(opt => opt.id === selectedState?.value)}
          onChange={option => {
            callback({
              label: option.label,
              value: option.id,
            })
          }}
          disabled={isLoading}
          placeholder={<TranslatedMessage id='age_gate.components.states.default' />}
          ariaLabel='Select state'
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      <TranslatedTextMessage id='SELECT_STATE_COPY' />
      {rawSelect(selector.states)}
    </React.Fragment>
  )
}
