import React, { FC } from 'react'

import { useRouter } from 'next/router'

import { LocalizedLink } from 'components/common/LocalizedLink'

import styles from './LanguageSelector.module.scss'

export interface Language {
  label: string
  value: string
}

type LanguageSelectorProps = {
  languages: Language[]
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({
  languages,
}) => {
  const { asPath } = useRouter()
  const buttonForLanguage = (language: Language) => (
    <LocalizedLink
      key={language.value}
      href={asPath}
      locale={language.value}
      className={styles.anchor}>
      {language.label}
    </LocalizedLink>
  )

  return (
    <div>
      {languages.map(language => buttonForLanguage(language))}
    </div>
  )
}
