import React, { FC } from 'react'

import { InlineNotification } from '@viewlio/juulio-bridge/src/apps/notification/components/InlineNotification'

import { TranslatedTextMessage } from 'components/ageGate/components/TranslatedTextMessage'

type Props = {
  messageKey: string
  stateLabel: string
}

export const BlockedStateWarningText: FC<Props> =
  ({ stateLabel, messageKey }) => {
    const text = (
      <TranslatedTextMessage
        id={messageKey}
        inlineData={{ state: stateLabel }}
      />
    )

    return (
      <InlineNotification text={text} className='inline-notification--error' />
    )
  }
